import React, { Component } from "react";

class GoogleSlidesEmbed extends Component {
	render() {
		return (
			<div>
				<h1>Google Slides Presentation</h1>
				{/* <iframe
					src='https://docs.google.com/presentation/d/e/2PACX-1vTcQ1DPS-PmIP9KDQW8_EmQFVt5AN5w5XHFnL0O0VVJkX3DBOti_uDBqDTs2jA6T5c2jQ-QE4YXY9_5/embed?start=false&loop=false&delayms=3000'
					frameBorder='0'
					width='960'
					height='569'
					allowFullScreen='true'
					mozallowfullscreen='true'
					webkitallowfullscreen='true'
					title='Google Slides Presentation'></iframe> */}

				<iframe
					src='https://docs.google.com/presentation/d/e/2PACX-1vQehcvDecNjqYbIFNpavdRPcd6C0o-pQahU4ap7IS7QJN5Nq-wouVdhqrNpsu4kmw/embed?start=false&loop=false&delayms=3000'
					frameborder='0'
					width={window.innerWidth}
					height='797'
					allowfullscreen='true'
					mozallowfullscreen='true'
					webkitallowfullscreen='true'></iframe>
			</div>
		);
	}
}

export default GoogleSlidesEmbed;
