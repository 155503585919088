import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";

export default class BannerPager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPage: 0,
		};
	}

	render() {
		const { performanceScore, prevPerformanceScore } = this.props;

		// Calculate percentage change
		let percentChange;
		if (prevPerformanceScore !== 0) {
			percentChange =
				((performanceScore - prevPerformanceScore) /
					prevPerformanceScore) *
				100;
		} else {
			percentChange = 0;
		}

		// Determine color based on change
		const color = percentChange > 0 ? "green" : "red";

		return (
			<View
				style={{
					width: "100%",
					padding: 10,
					// backgroundColor: "#171A20",

					backgroundColor: "rgb(255, 232, 77)",
					// backgroundColor: APP_COLOURS.BG3,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						width: "100%",
						maxWidth: 800,
						padding: 10,
						flexDirection: "row",
					}}>
					<View
						style={{
							padding: 10,
							flex: 1,
						}}>
						<Text
							style={{
								fontSize: 22,
								color: APP_COLOURS.BG2,
							}}>
							<Font family='Montserrat' weight={500}>
								Website audit report
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 35,
								color: APP_COLOURS.BG2,
							}}>
							<Font family='Montserrat' weight={800}>
								Revlon.co.za
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 25,
								color: APP_COLOURS.BG2,
							}}>
							<Font family='Montserrat' weight={300}>
								October 2024
							</Font>
						</Text>
					</View>
					<View
						style={{
							padding: 10,
							// flex: 1,
							borderRadius: 15,
							borderWidth: 1,
							borderColor: APP_COLOURS.BG3,
						}}>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.BG2,
							}}>
							<Font family='Montserrat' weight={500}>
								Performance Score
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 35,
								color: APP_COLOURS.BG2,
							}}>
							<Font family='Montserrat' weight={800}>
								{(performanceScore * 100).toFixed(0)}%
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 14,
								color: color,
							}}>
							<Font family='Montserrat' weight={600}>
								{percentChange > 0 ? "Up" : "down"}{" "}
								{percentChange.toFixed(0)}%
							</Font>
						</Text>
					</View>
				</View>
			</View>
		);
	}
}
