import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { useParams, useNavigate } from "react-router-dom";
import TrackerOverview from "./TRACKER";
import { APP_COLOURS } from "../APP_VARS";

function LiveDashboard() {
	const { token, clientid } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const authenticateUser = async () => {
			try {
				const response = await fetch(
					"https://workflow.tetrice.co.za/webhook/fe67312d-49c2-4525-ab76-068d3a231080",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							clientId: clientid,
							token: token,
						}),
					},
				);

				if (response.ok) {
					const data = await response.json();
					sessionStorage.setItem("accessToken", data.accessToken);
					navigate(
						"/tracker/revlon1234/iaWF0IjoxNzE5OTIzNjk5fQ?token=1234",
					);
				} else {
					console.error("Authentication failed");
					setIsAuthenticated(false);
				}
			} catch (error) {
				console.error("Authentication error:", error);
				setIsAuthenticated(false);
			} finally {
				setIsLoading(false);
			}
		};

		authenticateUser();
	}, [token, clientid, navigate]);

	if (isLoading) {
		return (
			<View
				style={{
					backgroundColor: APP_COLOURS.BG2,
					height: window.innerHeight,
					width: window.innerWidth,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Text style={{ color: "#fff", fontSize: 20 }}>Loading...</Text>
			</View>
		);
	}

	if (!isAuthenticated && !isLoading) {
		return (
			<View
				style={{
					backgroundColor: APP_COLOURS.BG2,
					height: window.innerHeight,
					width: window.innerWidth,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Text style={{ color: "#fff", fontSize: 20 }}>
					Authentication failed. Please try again.
				</Text>
			</View>
		);
	}

	return null;
}

export default LiveDashboard;
