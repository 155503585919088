import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import DigitalReport from ".";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DigitalUrl() {
	const { auditid, token } = useParams();
	const query = useQuery();

	// '3456322345'

	return (
		<DigitalReport
			auditid={auditid}
			editableDoc={JSON.parse(query.get("dev"))}
		/>
	);
}

export default DigitalUrl;
