import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import * as findTarget from "../assets/64198-find-target.json";
import * as creation from "../assets/134987-expo-logic-eventreg-marketing-lp-hero.json";
import * as ecosystem from "../assets/86836-data-dashboard.json";
import * as infographics from "../assets/91239-customer.json";
import * as customer from "../assets/28608-make-payment.json";
import * as brandidentity from "../assets/122694-brand-identity.json";
import * as animationData2 from "../assets/23626-infographics-circle.json";
import * as content from "../assets/141255-influencer-green.json";
import * as social from "../assets/72675-social-media.json";
import { crudGetPresentationData } from "../../CRUDHELPER.js";
import { APP_COLOURS, screenHeight } from "../../APP_VARS.js";
import EtailerAuditPage from "./EtailerAudit.js";
import VTOPage from "./VTOPage.js";
import ProductListingPage from "./ProductListing.js";
import Font from "react-font";
// import ProposalPageEditor from "./ProposalPageEditor.js";
import QuillEditor from "./Editor/QuillEditor.js";
import BannerPager from "./BannerPager.js";
import TeamTetrice from "./TeamTetrice.js";
import TableOfContents from "./TableOfContents.js.js";
import MyQuillEditor from "./Editor/QuillEditor.js";
import AuditIntroPage from "./AuditIntroPage.js";
// import LazyPresentation from "./Editor/OpenPresentationLaz.js";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class DigitalReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			proposalData: "",
			proposalPages: [],
			proposalPageData: "",
			lockContent: true,

			selectedPage: 0,

			auditid: this.props.auditid,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 2000);
	}

	fetchProposalData = async () => {
		this.setState({
			loading: true,
			proposalData: "",
			proposalPages: [],
			proposalPageData: "",
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/b860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				_id: "wbSf4WOgIlrS6HUL2FOB",
				title: "Platform Audit",
				description:
					"Platform audit of revlon.co.za and all it's respective ecommerce partner pages.",
				createdBy: {
					avatar: "4",
					fullName: "Wesley Cheia",
				},
				title2: "Q1 & Q2 - 2024",
				subHeading: "revlon.co.za",
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalData: data,
				});
				this.fetchProposalPages(data._id);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};
	fetchProposalPages = async (_id) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/a61608aa-7bdb-4ca0-88ce-58ce856ee0f4";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				proposal_id: _id,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalPages: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	updateDocument = () => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook-test/b860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				_id: "wbSf4WOgIlrS6HUL2FOB",
				title: "Platform Audit",
				description:
					"Platform audit of revlon.co.za and all it's respective ecommerce partner pages.",
				createdBy: {
					avatar: "4",
					fullName: "Wesley Cheia",
				},
				title2: "Q1 & Q2 - 2024",
				subHeading: "revlon.co.za",
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalData: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	getLargeHtmlData = (geturl, next) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				url: geturl,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((html) => {
				return next(html);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getPresentationData = async (sheetName) => {
		let dd = [];
		await crudGetPresentationData(sheetName, (s, d) => {
			if (s === 200) {
				return (dd = d);
			}
		});
		await console.log(dd);
		return dd;
	};

	render() {
		let screenWidth = window.innerWidth;
		const isSmallScreen = screenWidth < 900;
		let fs = window.innerWidth * 0.07;

		let pp = isSmallScreen ? 12 : 15;
		let h3 = pp * 1.1618;
		let h2 = pp * 1.298;
		let h1 = h2 * 1.01618;
		let h0 = h1 * 1.9618;
		let maxFont = 40;

		let mw = 1000;

		const pages = [
			{
				title: "Introduction",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"into_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},
			{
				title: "Overview",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							brandidentity,
							"Digital 360 Report Overview",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"Overview_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},
			{
				title: "Website",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							ecosystem,
							"Website performance",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"performance_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(h0, "Performance Stats")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"websiteperformance_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(h0, "Content analysis")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"content_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(h0, "VTO Audits")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"vtoaudit_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(h0, "Security Assessment")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"security_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(h0, "Compliance & Legal")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"compliance_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>

						{this.pageTitleComp(
							h0,
							"Future Updates & Recommendations",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"updates_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},

			{
				title: "SEO & SEM",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							creation,
							"SEO Audit",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"seo_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},
			{
				title: "Social Media",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							content,
							"Social Media Overview",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"smoverview_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
						{this.pageTitleComp(h0, "Channels")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"smchannels_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
						{this.pageTitleComp(h0, "Content performance")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"smcontentperformance_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
						{this.pageTitleComp(h0, "Social Media Reports")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"smreports_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},

			{
				title: "Community Management",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							social,
							"Community Management",
						)}

						<MyQuillEditor
							proposalData={{}}
							presentation_id={"cmoverview_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
						{this.pageTitleComp(h0, "Sentiment")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"cmsentiment_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
						{this.pageTitleComp(h0, "Complaints & Resolutions")}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"cmcomplaints_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},

			{
				title: "Performance Media",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							findTarget,
							"Performance Media",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"digitalpaid_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},

			{
				title: "PR & Events",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							brandidentity,
							"PR & Events",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"prevents_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},

			{
				title: "Retailer pages",
				comp: (
					<EtailerAuditPage
						{...this.state}
						h0={h0}
						h1={h1}
						h2={h2}
						pp={pp}
						{...{
							title: "E-Commerce Retailers",
							title2: "",
							animationData: brandidentity,
							items: [],
							sheetName: "OverviewPage",
						}}
					/>
				),
			},
			{
				title: "Product Listings",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							customer,
							"Product Listings",
						)}
						<ProductListingPage
							{...this.state}
							h0={h0}
							h1={h1}
							h2={h2}
							pp={pp}
							presentation_id={"listings_3456322345"}
							{...{
								title: "Product Listings",
								title2: "",
								animationData: infographics,
								items: [],
								sheetName: "OverviewPage",
							}}
						/>
					</View>
				),
			},
			{
				title: "Ad Hoc Showcase",
				comp: (
					<View
						style={{
							width: "100%",
							height: "auto",
						}}>
						{this.headdingComp(
							h1,
							h2,
							pp,
							h0,
							ecosystem,
							"Ad Hoc Showcase",
						)}
						<MyQuillEditor
							proposalData={{}}
							presentation_id={"adhocshowcase_3456322345"}
							reloadData={() => {
								this.setState({
									lockContent: true,
								});
							}}
							lockContent={this.state.lockContent}
							fontSizes={{
								"--h1": `${h0}px`,
								"--h2": `${h1}px`,
								"--h4": `${h3}px`,
								"--pp": `${pp}px`,
							}}
						/>
					</View>
				),
			},
			{ title: "Team tetrice", comp: <TeamTetrice /> },
		];

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					{/* <Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/> */}
					<View
						style={{
							flex: 1,
							marginRight: 20,
							justifyContent: "center",
						}}>
						<Text
							style={{
								fontSize: 55,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
					</View>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight,
						overflow: "hidden",
						// backgroundColor: "#171A20",
						// backgroundColor: "#FBFBFB",

						backgroundColor: "#171A20",
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollRef = ref;
						}}
						overScrollMode='never'
						showsVerticalScrollIndicator={false}
						contentContainerStyle={{
							alignItems: "center",
						}}>
						{this.state.selectedPage === 0 ? (
							<View style={{ width: "100%" }}>
								<BannerPager
									title={"Digital 360 Report"}
									brand={"REVLON Cosmetics"}
									date={"October 2024"}
									performanceScore={0.83}
									prevPerformanceScore={0.73}
								/>
							</View>
						) : null}

						<View
							style={{
								flexDirection: isSmallScreen ? "column" : "row",
								height: window.innerHeight,
								width: "100%",
							}}>
							<View style={{}}>
								<Text
									style={{
										fontSize: 25,
										color: "#FFF",
										padding: 10,
									}}>
									<Font family='Poppins' weight={800}>
										tetrice
									</Font>
								</Text>
								{this.props.editableDoc ? (
									<TouchableOpacity
										onPress={() => {
											this.setState({
												lockContent:
													!this.state.lockContent,
											});
										}}>
										<View
											style={{
												width: 120,
												alignItems: this.state
													.lockContent
													? "flex-start"
													: "flex-end",
												backgroundColor:
													APP_COLOURS.BG4,
												borderRadius: 15,
												height: 40,
											}}>
											<View
												style={{
													width: 80,
													height: 40,
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 13,
													backgroundColor: !this.state
														.lockContent
														? "white"
														: APP_COLOURS.BLUE,
													paddingHorizontal: 10,
												}}>
												<Text
													style={{
														fontSize: 13,
														color: !this.state
															.lockContent
															? "blue"
															: APP_COLOURS.DESELECTEDCOLOR,
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{!this.state.lockContent
															? "Unlocked"
															: "Locked"}
													</Font>
												</Text>
											</View>
										</View>
									</TouchableOpacity>
								) : null}
								<TableOfContents
									pages={pages.map((d) => d.title)}
									isSmallScreen={isSmallScreen}
									selectedPage={(d) => {
										this.setState({
											selectedPage: "",
										});
										setTimeout(() => {
											this.setState({
												selectedPage: d,
											});
										}, 500);
									}}
								/>
							</View>
							<ScrollView
								ref={(ref) => {
									this.scrollRef2 = ref;
								}}
								overScrollMode='never'
								showsVerticalScrollIndicator={false}
								contentContainerStyle={{
									alignItems: "center",
								}}
								scrollEventThrottle={16}>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										padding: 20,
										// paddingTop: 0,
									}}>
									<View
										style={{
											width: "100%",
											maxWidth: 1000,
											marginBottom: 200,
											marginHorizontal: "auto",
										}}>
										<>
											{this.state.selectedPage === ""
												? null
												: pages[this.state.selectedPage]
														.comp}
											<View
												style={{
													flex: 1,
													flexDirection: "row",
													alignItems: "center",
													justifyContent:
														"space-between",
													marginBottom: "10%",
													marginTop: 50,
												}}>
												<View
													style={{
														width: "50%",
														marginBottom: 20,
														maxWidth: 250,
													}}>
													<img
														src={require("../assets/services.png")}
														alt='img'
														className='responsive'
													/>
												</View>
												<View
													style={{
														width: "50%",
														marginBottom: 20,
														maxWidth: 250,
													}}>
													<img
														src={require("../assets/infod.png")}
														alt='img'
														className='responsive'
													/>
												</View>
											</View>
										</>
									</View>
								</View>
							</ScrollView>
							<View
								style={{
									width: isSmallScreen ? 0 : 200,
								}}
							/>
						</View>
					</ScrollView>
				</View>
			);
	}

	headdingComp = (h1, h2, pp, h0, animationData, title) => {
		let h = window.innerHeight * 0.75;
		let w = window.innerWidth * 0.75;

		return (
			<View
				style={{
					width: "100%",
					// height: "auto",
					alignItems: "center",
					flexDirection: "row",
					flexWrap: "wrap",
				}}>
				<View
					style={{
						flex: 1,
						minWidth: 300,
					}}>
					<Text
						style={{
							fontSize: h0 * 1.1,
							color: "#FFF",
							fontFamily: "Montserrat",
							fontWeight: "800",
						}}>
						{title}
					</Text>

					<Text
						ellipsizeMode='clip'
						style={{
							fontSize: h1,
							opacity: 0.7,
							color: "#FFF",
							// fontFamily: "Gotham",
							flexWrap: "wrap",
						}}>
						{this.props.title2}
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						minWidth: 300,
					}}>
					<View style={{}}>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={320}
							width={300}
						/>
					</View>
				</View>
			</View>
		);
	};

	pageTitleComp = (h0, title) => {
		return (
			<>
				<View
					style={{
						width: 120,
						height: 4,
						backgroundColor: "rgb(255, 232, 77)",
						marginBottom: 20,
						borderRadius: 10,
						marginTop: 100,
					}}
				/>
				<View style={{}}>
					<View
						style={{
							width: "100%",
							// height: "auto",
							alignItems: "center",
							flexDirection: "row",
							flexWrap: "wrap",
						}}>
						<View
							style={{
								flex: 1,
								minWidth: 300,
							}}>
							<Text
								style={{
									fontSize: h0 * 1,
									color: "#eef2fc",
									fontFamily: "Montserrat",
									fontWeight: "700",
								}}>
								{title}
							</Text>
						</View>
					</View>
				</View>
			</>
		);
	};
}
