import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import logo_tetrice from "../assets/tetrice_logo_dark.png";
import { APP_COLOURS } from "../../APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class AuditIntroPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: true,
		};
	}

	render() {
		let h = this.props.screenHeight;
		let w = this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: window.innerHeight * 0.3,
				}}>
				<View
					style={{
						width: "100%",

						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* <View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View> */}

					<View
						style={{
							width: "100%",
							maxWidth: 1000,
						}}>
						<div
							className={
								this.state.inView ? `reveal` : `fadeOut`
							}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h / 2,
									// width: "100%",
								}}>
								<View
									style={{
										// flex: 1,
										width: "100%",
									}}>
									<Text
										style={{
											fontSize: 40,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 20,
											marginTop: 20,
										}}>
										<Font family='Montserrat' weight={700}>
											Introduction
										</Font>
									</Text>

									{/* <Text
										style={{
											fontSize: 14,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											textAlign: "justify",
										}}>
										<Font family='Montserrat' weight={300}>
											We thank you for choosing tetrice
											consulting as your preferred
											supplier for PR management, social
											media community management, and
											content management. As a leading
											consulting firm, we specialize in
											creating comprehensive and
											successful PR and community
											management strategies that deliver
											results. Our goal is to ensure we
											add value to our clients and utilise
											our collective expertise in assiting
											Revlon to increase its brand
											awareness and engage with its target
											audience effectively
										</Font>
									</Text> */}

									<section
										style={{
											// color: "#FFF",
											color: APP_COLOURS.TEXTCOLOR,
										}}
										class='executive-summary'>
										<h2>Executive Summary</h2>
										<p>
											This report presents a comprehensive
											audit of Revlon South Africa's
											official website,{" "}
											<a
												href='https://www.revlon.co.za/'
												target='_blank'
												rel='noopener noreferrer'>
												revlon.co.za
											</a>
											, conducted for the month of{" "}
											<strong>October 2024</strong>. The
											primary objective of this audit is
											to evaluate the website's
											performance across key areas,
											including traffic growth, user
											engagement, SEO effectiveness,
											content quality, technical health,
											and compliance with web standards.
										</p>
										<h3>Key Findings:</h3>
										<ul>
											<li>
												<strong>Traffic Growth:</strong>{" "}
												The website experienced a{" "}
												<strong>X%</strong> increase in
												overall traffic compared to the
												previous month, with organic
												search traffic rising by{" "}
												<strong>Y%</strong> due to
												improved SEO strategies.
											</li>
											<li>
												<strong>
													User Engagement:
												</strong>{" "}
												There was a notable improvement
												in user engagement metrics,
												including a <strong>Z%</strong>{" "}
												decrease in bounce rate and an
												increase in average session
												duration to{" "}
												<strong>[Time]</strong> minutes.
											</li>
											<li>
												<strong>
													SEO Performance:
												</strong>{" "}
												Several targeted keywords have
												moved into the top 10 search
												results on Google, enhancing the
												site's visibility.
											</li>
											<li>
												<strong>
													Content Updates:
												</strong>{" "}
												The addition of new product
												pages and a refreshed blog
												section contributed to increased
												user interest and repeat visits.
											</li>
											<li>
												<strong>
													Technical Health:
												</strong>{" "}
												Site speed on desktop is
												satisfactory; however, mobile
												performance requires
												optimization due to longer load
												times.
											</li>
											<li>
												<strong>Security:</strong> The
												website is secured with a valid
												SSL certificate, but a
												vulnerability scan revealed
												outdated plugins that need
												immediate attention.
											</li>
										</ul>
										<h3>Recommendations:</h3>
										<ul>
											<li>
												<strong>
													Address Technical Issues:
												</strong>{" "}
												Optimize images and code to
												improve mobile load times and
												fix broken links found in the
												blog section.
											</li>
											<li>
												<strong>
													Enhance SEO Strategies:
												</strong>{" "}
												Continue to focus on keyword
												optimization and backlink
												building to sustain and improve
												search rankings.
											</li>
											<li>
												<strong>
													Update Security Measures:
												</strong>{" "}
												Update all outdated plugins and
												conduct regular security audits
												to protect against
												vulnerabilities.
											</li>
											<li>
												<strong>
													Future Content Plans:
												</strong>{" "}
												Develop a content calendar
												focusing on current beauty
												trends to engage the audience
												further.
											</li>
										</ul>
										<p>
											Overall, the website shows positive
											growth and engagement trends, but
											attention to technical and security
											aspects will enhance user experience
											and protect the brand's online
											presence.
										</p>
									</section>

									{/* Introduction Section */}
									<section
										style={{
											// color: "#FFF",
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<h2>Introduction</h2>
										<h3>Purpose of the Audit</h3>
										<p>
											The purpose of this audit is to
											provide Revlon South Africa with a
											detailed analysis of their website,{" "}
											<a
												href='https://www.revlon.co.za/'
												target='_blank'
												rel='noopener noreferrer'>
												revlon.co.za
											</a>
											, to identify strengths, weaknesses,
											and opportunities for improvement.
											As a prominent player in the
											cosmetics industry, maintaining a
											robust and user-friendly online
											presence is critical for brand
											positioning, customer engagement,
											and driving sales.
										</p>
										<h3>Scope of the Audit</h3>
										<p>
											This audit covers the following key
											areas:
										</p>
										<ol>
											<li>
												<strong>
													Website Performance Metrics:
												</strong>{" "}
												Analysis of traffic patterns,
												user behavior, and engagement
												levels.
											</li>
											<li>
												<strong>SEO Evaluation:</strong>{" "}
												Assessment of on-page and
												off-page SEO factors affecting
												search engine rankings.
											</li>
											<li>
												<strong>
													Content Quality:
												</strong>{" "}
												Review of website content for
												relevance, originality, and
												engagement potential.
											</li>
											<li>
												<strong>
													User Experience (UX):
												</strong>{" "}
												Examination of site navigation,
												mobile responsiveness, and
												overall usability.
											</li>
											<li>
												<strong>
													Technical Health:
												</strong>{" "}
												Evaluation of site speed,
												functionality, and technical
												optimizations.
											</li>
											<li>
												<strong>
													Security Assessment:
												</strong>{" "}
												Verification of security
												measures in place to protect
												user data and site integrity.
											</li>
											<li>
												<strong>
													Compliance Checks:
												</strong>{" "}
												Ensuring adherence to legal
												requirements and accessibility
												standards.
											</li>
										</ol>
										<h3>Methodology</h3>
										<p>
											The audit was conducted using a
											combination of analytical tools and
											manual review processes, including:
										</p>
										<ul>
											<li>
												<strong>
													Google Analytics and Search
													Console
												</strong>{" "}
												for traffic and SEO data.
											</li>
											<li>
												<strong>
													SEO auditing tools
												</strong>{" "}
												like SEMrush and Ahrefs for
												keyword and backlink analysis.
											</li>
											<li>
												<strong>
													Page speed testing tools
												</strong>{" "}
												such as Google PageSpeed
												Insights for performance
												metrics.
											</li>
											<li>
												<strong>
													Security scanning tools
												</strong>{" "}
												to identify vulnerabilities and
												outdated components.
											</li>
											<li>
												<strong>Manual testing</strong>{" "}
												on various devices and browsers
												to assess user experience and
												accessibility.
											</li>
										</ul>
										<h3>Objectives</h3>
										<ul>
											<li>
												<strong>
													Identify Areas of Success:
												</strong>{" "}
												Highlight what the website is
												doing well to continue
												leveraging these strengths.
											</li>
											<li>
												<strong>
													Detect Issues and
													Challenges:
												</strong>{" "}
												Uncover any problems that may
												hinder user experience or site
												performance.
											</li>
											<li>
												<strong>
													Provide Actionable
													Recommendations:
												</strong>{" "}
												Offer clear and practical
												solutions to address identified
												issues.
											</li>
											<li>
												<strong>
													Support Business Goals:
												</strong>{" "}
												Align website performance with
												Revlon's broader marketing and
												sales objectives.
											</li>
										</ul>
										<p>
											By delivering this audit, we aim to
											equip Revlon South Africa with the
											insights necessary to enhance their
											website's effectiveness, ensuring it
											remains a valuable asset in their
											digital strategy.
										</p>
									</section>
								</View>
							</View>
						</div>
					</View>
				</View>
			</div>
		);
	}
}
