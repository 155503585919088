import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	Switch,
	Button,
	StyleSheet,
} from "react-native";

const ProductForm = () => {
	const [productName, setProductName] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [seoScore, setseoScore] = useState("");

	const [productPage, setProductPage] = useState(true);
	const [primaryImagery, setPrimaryImagery] = useState(true);
	const [secondaryImagery, setSecondaryImagery] = useState(true);
	const [carouselImagery, setCarouselImagery] = useState(true);
	const [detailsDescriptionHowToUse, setDetailsDescriptionHowToUse] =
		useState(true);
	const [etailersLinks, setEtailersLinks] = useState([""]);
	const [variants, setVariants] = useState([]);

	const handleEtailerLinkChange = (index, value) => {
		const links = [...etailersLinks];
		links[index] = value;
		setEtailersLinks(links);
	};

	const addEtailerLink = () => {
		setEtailersLinks([...etailersLinks, ""]);
	};

	const handleSubmit = () => {
		const data = {
			productName,
			imageUrl,
			productPage,
			primaryImagery,
			secondaryImagery,
			carouselImagery,
			detailsDescriptionHowToUse,
			etailersLinks,
			variants,
			seoScore,
		};

		fetch(
			"https://workflow.tetrice.co.za/webhook-test/00a29ca0-e5ff-4cdb-a94c-35d596225cdd",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			},
		)
			.then((response) => {
				if (response.ok) {
					alert("Product submitted successfully!");
				} else {
					alert("Error submitting product.");
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<View style={styles.container}>
			<Text style={styles.label}>Product Name:</Text>
			<TextInput
				style={styles.input}
				value={productName}
				onChangeText={setProductName}
				placeholder='Enter product name'
			/>

			<Text style={styles.label}>Image URL:</Text>
			<TextInput
				style={styles.input}
				value={imageUrl}
				onChangeText={setImageUrl}
				placeholder='Enter image URL'
			/>

			<Text style={styles.label}>SEO Score:</Text>
			<TextInput
				style={styles.input}
				value={seoScore}
				onChangeText={setseoScore}
				placeholder='Enter SEO Score'
			/>

			<View style={styles.switchContainer}>
				<Text style={styles.label}>Product Page:</Text>
				<Switch value={productPage} onValueChange={setProductPage} />
			</View>

			<View style={styles.switchContainer}>
				<Text style={styles.label}>Primary Imagery:</Text>
				<Switch
					value={primaryImagery}
					onValueChange={setPrimaryImagery}
				/>
			</View>

			<View style={styles.switchContainer}>
				<Text style={styles.label}>Secondary Imagery:</Text>
				<Switch
					value={secondaryImagery}
					onValueChange={setSecondaryImagery}
				/>
			</View>

			<View style={styles.switchContainer}>
				<Text style={styles.label}>Carousel Imagery:</Text>
				<Switch
					value={carouselImagery}
					onValueChange={setCarouselImagery}
				/>
			</View>

			<View style={styles.switchContainer}>
				<Text style={styles.label}>
					Details Description How To Use:
				</Text>
				<Switch
					value={detailsDescriptionHowToUse}
					onValueChange={setDetailsDescriptionHowToUse}
				/>
			</View>

			<Text style={styles.label}>Etailer Links:</Text>
			{etailersLinks.map((link, index) => (
				<TextInput
					key={index}
					style={styles.input}
					value={link}
					onChangeText={(value) =>
						handleEtailerLinkChange(index, value)
					}
					placeholder={`Link ${index + 1}`}
				/>
			))}
			<Button title='Add Another Link' onPress={addEtailerLink} />

			{/* You can implement variant inputs here if needed */}

			<Button title='Submit' onPress={handleSubmit} color='#841584' />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: 20,
		borderRadius: 10,
	},
	label: {
		marginTop: 15,
		fontWeight: "bold",
	},
	input: {
		height: 40,
		borderColor: "gray",
		borderWidth: 1,
		marginTop: 5,
		paddingLeft: 10,
	},
	switchContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 15,
	},
});

export default ProductForm;
