import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";
import { ScrollView } from "react-native-web";

export default class TableOfContents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPage: 0,
		};
	}

	render() {
		return (
			<View
				style={{
					padding: 10,
				}}>
				<Text
					style={{
						fontSize: 10,
						color: APP_COLOURS.DESELECTEDCOLOR,
						padding: 10,
						textAlign: "center",
					}}>
					<Font family='Montserrat' weight={300}>
						TABLE OF CONTENTS
					</Font>
				</Text>
				<View
					style={{
						flexDirection: this.props.isSmallScreen
							? "row"
							: "column",
					}}>
					<ScrollView horizontal={this.props.isSmallScreen}>
						{this.props.pages.map((d, i) => (
							<TouchableOpacity
								style={{
									width: this.props.isSmallScreen
										? "auto"
										: 200,
									// alignItems: "center",
									justifyContent: "center",
									borderRadius: 9,
									// borderWidth: 1,
									padding: this.props.isSmallScreen ? 7 : 12,
									marginBottom: this.props.isSmallScreen
										? 2
										: 10,
									// borderColor:
									// 	this.state.selectedPage === i
									// 		? "traansparent"
									// 		: "#FFF",
									backgroundColor:
										this.state.selectedPage === i
											? APP_COLOURS.BLUE
											: APP_COLOURS.BG3,
									// height: 60,
									marginRight: 5,
								}}
								onPress={() => {
									this.setState({
										selectedPage: i,
									});
									this.props.selectedPage(i);
								}}>
								<View>
									<Text
										style={{
											fontSize: this.props.isSmallScreen
												? 10
												: 14,
											color: APP_COLOURS.DESELECTEDCOLOR,
										}}>
										<Font family='Montserrat' weight={500}>
											{d}
										</Font>
									</Text>
								</View>
							</TouchableOpacity>
						))}
					</ScrollView>
				</View>
			</View>
		);
	}
}
