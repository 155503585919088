import React, { Component } from 'react';
import { View, Text } from 'react-native';
import FileBase64 from 'react-file-base64';

import './Input.css';

class ImageUploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: [],
			borderHighlight: false,
		};
	}

	getFiles(files) {
		console.log('files', files);
		this.setState({ file: files });

		// WE USING A FOR LOOP TO SAVE THE FILES INCASE THEY ARE MULTIPLE
		for (var i = 0; i < files.length; i++) {
			if (files[i].type === 'image/png') {
				let replace_ = 'data:image/png;base64,';
				let myStr = files[i].base64;
				let cleanString = myStr.replace(replace_, '');
				console.log(cleanString);
				this.props.base64String(cleanString);
			} else {
				alert('Only PNG images accepted');
			}

			// const objectTosend = {
			// 	name: files[i].name,
			// 	fileBase64: files[i].base64,
			// 	size: files[i].size,
			// 	selectedFolder: folderid,
			// };
		}
	}

	SaveFile = (objectToSend) => {
		this.setState({ loading: true });
		let obj = JSON.stringify(objectToSend);
		console.log(obj);
	};

	render() {
		return (
			<View
				style={{
					zIndex: 99,
					borderColor: this.state.borderHighlight ? 'blue' : 'white',
					borderWidth: 1,
					borderRadius: 10,
					borderStyle: 'dotted',
				}}>
				<View
					onDragEnter={() =>
						this.setState({
							borderHighlight: true,
						})
					}
					onDragLeave={() =>
						this.setState({
							borderHighlight: false,
						})
					}
					style={{
						opacity: 0,
					}}>
					<FileBase64
						multiple={true}
						onDone={this.getFiles.bind(this)}
						onDragEnter={() =>
							this.setState({
								borderHighlight: true,
							})
						}
						onDragLeave={() =>
							this.setState({
								borderHighlight: false,
							})
						}
					/>
				</View>
			</View>
		);
	}
}

export default ImageUploader;
