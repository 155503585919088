import React, { useState, useEffect } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
} from "react-native";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import Quill styles
import "./custome.css";

const MyQuillEditor = ({
	proposalData,
	presentation_id,
	reloadData,
	lockContent,
	fontSizes,
}) => {
	const [editorHtml, setEditorHtml] = useState("");
	const [saveHtml, setSaveHtml] = useState(false);

	useEffect(() => {
		fetchProposalPages(presentation_id);
		setSaveHtml(lockContent);
	}, [presentation_id, lockContent]);

	const modules = {
		toolbar: {
			container: [
				[{ header: "1" }, { header: "2" }, { font: [] }],
				[{ size: [] }],
				["bold", "italic", "underline", "strike", "blockquote"],
				[
					{ list: "ordered" },
					{ list: "bullet" },
					{ indent: "-1" },
					{ indent: "+1" },
				],
				["link", "image", "video"],
				["clean"],
			],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	const handleSave = async () => {
		setSaveHtml(true);

		try {
			const chunkSize = 1024 * 1024; // 1MB chunks (adjust as needed)
			const totalChunks = Math.ceil(editorHtml.length / chunkSize);

			for (let i = 0; i < totalChunks; i++) {
				const chunk = editorHtml.slice(
					i * chunkSize,
					(i + 1) * chunkSize,
				);

				const response = await fetch(
					"https://workflow.tetrice.co.za/webhook/7d2d7a18-f98e-4da0-ac00-dccccb7bf46e",
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							html: chunk,
							proposal_id: proposalData._id,
							_id: presentation_id,
							totalChunks,
							currentChunk: i + 1, // Chunk index (1-based)
						}),
					},
				);

				if (!response.ok) {
					throw new Error("Failed to upload chunk");
				}
			}

			// All chunks uploaded successfully
			console.log("All chunks uploaded successfully");
			reloadData();
		} catch (error) {
			console.error("Error uploading chunks:", error);
		} finally {
			setSaveHtml(false); // Reset save state
		}
	};

	const fetchProposalPages = async (_id) => {
		let url =
			"https://workflow.tetrice.co.za/webhook/329da069-b6b0-426c-bf8e-ac076ba42f50";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				_id: _id,
			}),
		};

		try {
			const response = await fetch(url, requestOptions);
			const data = await response.json();

			if (response.status === 200) {
				setEditorHtml(data.html);
				// console.log(data)
			} else {
				console.error("Failed to fetch data:", data);
			}
		} catch (error) {
			console.error("Error fetching proposal pages:", error);
		}
	};

	return (
		<div className='ql-overflow'>
			{!saveHtml ? (
				<>
					<ReactQuill
						theme='snow'
						value={editorHtml}
						onChange={handleChange}
						modules={modules}
						formats={formats}
						className='ql-overflow'
					/>
					{/* <TouchableOpacity> */}
					{/* <button onClick={handleSave}>Save</button> */}
					<div className='cursor'>
						<TouchableOpacity
							style={{
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								marginVertical: 25,
								flexDirection: "row",
								overflow: "hidden",
							}}
							onPress={() => {
								handleSave();
							}}>
							<Image
								source={require("./button.svg").default}
								style={{
									width: "101%",
									height: 45,
									resizeMode: "cover",
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									marginTop: 2,
									fontWeight: "600",
									color: "#FFF",
									position: "absolute",
								}}>
								Save & Update
							</Text>
						</TouchableOpacity>
					</div>
				</>
			) : (
				<div
					className='ql-overflow'
					style={fontSizes}
					dangerouslySetInnerHTML={{ __html: editorHtml }}
				/>
			)}

			{/* <View
				style={{
					flex: 1,
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: "10%",
					marginTop: 50,
				}}>
				<View
					style={{
						width: "50%",
						marginBottom: 20,
						maxWidth: 250,
					}}>
					<img
						src={require("../../assets/services.png")}
						alt='img'
						className='responsive'
					/>
				</View>
				<View
					style={{
						width: "50%",
						marginBottom: 20,
						maxWidth: 250,
					}}>
					<img
						src={require("../../assets/infod.png")}
						alt='img'
						className='responsive'
					/>
				</View>
			</View> */}
		</div>
	);
};

export default MyQuillEditor;
